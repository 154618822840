import React from "react"
import data from "../yourdata"

const Skills = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="skills-container">
          <h1>Skills</h1>
          <h3>Interpersonal Skills</h3>
          <p style={{textAlign: "center"}}>Leader  |  Team Player  |  Communicator  |  Collaborator</p>
          <p style={{textAlign: "center"}}>Project Management  |  Event Planning  |  Relationship Management</p>
          <br></br>
          <br></br>
          <h3 style={{textAlign: "center"}}>Full Stack Development  |  Data Modelling & Analysis  |  UIUX  |  Blockchain</h3>
          <div className="skills-grid">
            {data.skills.map((skill, index) => (
              <div className="skill" key={index}>
                <img src={skill.img} alt="css"></img>
                <p>{skill.title}</p>
                {/* <p>{skill.para}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
