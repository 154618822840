import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Promotion = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="promotion-container">
          <Fade bottom cascade>
            <h1>{data.promotionHeading}</h1>
          </Fade>
          <h3>{data.promotionPara1}</h3>
          <p>{data.promotionPara2}</p>
          <p>{data.promotionPara3}</p>
          <p>{data.promotionPara4}</p>
          <br></br>
          <h3>{data.promotionPara5}</h3>
          <p>{data.promotionPara6}</p>
        </div>
      </div>
    </div>
  )
}

export default Promotion
