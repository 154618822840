// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"

// Social Icons
import githubIcon from "./images/github.svg"
import linkedinIcon from "./images/linkedin.svg"
import mailIcon from "./images/mail.svg"
import javaIcon from "./images/java.png"
import pythonIcon from "./images/python.png"
import rIcon from "./images/R.svg"
import sqlIcon from "./images/sql.svg"
import zilIcon from "./images/zilliqa.svg"
import mongoIcon from "./images/mongoDB.png"
import adobeIcon from "./images/adobe.png"
import figmaIcon from "./images/figma.png"
import awsIcon from "./images/aws.png"
import linuxIcon from "./images/linux.png"

// Profile Photo
import bbg from './images/bbg.jpg'

// Projects images
import zilProj from "./images/projects/zilliqa.png"
import themeparkProj from "./images/projects/themepark.png"
import capstoneProj from "./images/projects/capstone.jpeg"
import netflixProj from "./images/projects/netflix.png"
import vizwizProj from "./images/projects/viz-wiz.png"
import uiuxProj from "./images/projects/uiux.png"
import jubeatProj from "./images/projects/jubeat.jpeg"
import axaProj from "./images/projects/axa.jpeg"
import gitletProj from "./images/projects/gitlet.png"
import moolahProj from "./images/projects/moolah.png"
import mlProj from "./images/projects/sentiment_analysis.png"
import bearmapsProj from "./images/projects/bearmaps.png"

export default {
  //   Header Details ---------------------
  name: "Ying Xuan | Verlyn",
  headerTagline: [
    //Line 1 For Header
    "Ying Xuan | Verlyn",
    //Line 2 For Header
    " ",
    // Line 3 For Header
    " ",
  ],
  //   Header Paragraph
  headerParagraph:
    "Achiever | Adaptable | Analytical",

  // Contact Email
  // contactEmail: "https://www.linkedin.com/in/yingxuanyeo/",
  contactEmail: "hello@yingxuanyeo.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Interactive Financial Valuation Visualisation",
      para:
        "Partnered with AXA Investment Managers, a dynamic and interactive financial valuation visualisation was ideated and developed, and is currently in use in the APAC market.",
      imageSrc:
        axaProj,
      tech:
        "JS, HTML, CSS",
      url: "/DBAPoster.pdf",
    },
    {
      title: "Gitlet",
      para:
        "Built a simplified distributed version-control file system, mimicking the basic functionalities of GitHub.",
      imageSrc:
        gitletProj,
      tech:
        "Java",
      url: "https://www.linkedin.com/in/yingxuanyeo/",
    },
    {
      title: "BearMaps",
      para:
        "A web mapping application with a simple routing algorithm that finds the shortest route between two points in Berkeley, California, USA.",
      imageSrc:
        bearmapsProj,
      tech:
        "Java",
      url: "https://www.linkedin.com/in/yingxuanyeo/",
    },
    {
      title: "jubeatNet",
      para:
        "Data-driven algorithms predicting finger pattern sequences of the famous Japanese arcade game jubeat.",
      imageSrc:
        jubeatProj,
      tech:
        "Python",
      url: "https://www.linkedin.com/in/yingxuanyeo/",
    },
    {
      title: "Capstone - AI Salad",
      para:
        "Partnered with Norgren, an Internet-of-Things (IoT) Enabled Smart Farming Monitoring Technology that visualises environmental variables in real-time, alongside analytics and provide recommendations for optimal plant growth using historical data.",
      imageSrc:
        capstoneProj,
      tech:
        "Python Django, ReactJS, SQL, AWS",
      url: "/CapstonePoster.pdf",
    },
    {
      title: "Netflix Recommendation System",
      para:
        "Restricted Boltzmann Machine (RBM) algorithm with extensions to predict Netflix movie ratings. Best Prediction Team.",
      imageSrc:
        netflixProj,
      tech:
        "Python",
      url: "https://www.linkedin.com/in/yingxuanyeo/",
    },
    {
      title: "NLP Sentiment Analysis",
      para:
        "Machine Learning Project - Sentiment Analysis of tweets in English, Chinese and Singlish using built-from-scratch Hidden Markov Model (First & Second Orders).",
      imageSrc:
        mlProj,
      tech:
        "Python",
      url: "https://github.com/yingxuan0806/50.007-ML-HMM-Project",
    },
    {
      title: "MyHealth",
      para:
        "A personal healthcare assistant designed for older adults, assisting them in their daily healthcare-related matters.",
      imageSrc:
        uiuxProj,
      tech:
        "Figma",
      url: "https://www.figma.com/proto/ksC326Jn1C3kDK86aVMdeL/UIUX?page-id=158%3A1446&node-id=161%3A1&viewport=317%2C231%2C0.02399827167391777&scaling=scale-down&starting-point-node-id=161%3A1",
    },
    {
      title: "Viz-Wiz",
      para:
        "An educational game to enhance visual proficiency with personal dashboard tracker. Best Project Score Team.",
      imageSrc:
        vizwizProj,
      tech:
        "R, HTML, CSS, JS",
      url: "https://yingxuan0806.github.io/Viz-Wiz/index.html",
    },
    {
      title: "Theme Park Simulator",
      para:
        "A scalable simulator of amusement park planning with statistical analysis.",
      imageSrc:
        themeparkProj,
      tech:
        "R, HTML, CSS, JS",
      url: "https://yingxuan0806.github.io/SMA-Roller-Coaster/",
    },
    {
      title: "Zilliqa Review Federation",
      para:
        "Proof of concept to implement a review federation using Zilliqa Blockchain. Initially designed to rate IPFS files, it can be extended to any type of text-based data (file hashes, serial numbers etc).",
      imageSrc:
        zilProj,
      tech:
        "Scilla (Zilliqa Blockchain)",
      url: "https://github.com/yingxuan0806/zilliqa-review-federation-poc",
    },
    {
      title: "Moolah",
      para:
        "P2P Lending Platform in Gaming Industry. Razer Fintech Digital Hackathon 2020, with Tin Kit, Jia Le & David. ",
      imageSrc:
        moolahProj,
      tech:
        "JS, MongoDB",
      url: "/moolahDeck.pdf",
    },
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Hello! I am a Technology Analyst in one of the global financial institutions. Being a strong believer that data is the new currency in our increasingly digitalised economy, I am highly passionate about the synergy between technology and finance. My areas of interest are in Asset Management (Trading) and Quantitative Finance.",
  aboutParaTwo:
    "Having a broad spectrum of experience in spearheading the ideation, planning, and execution of a multitude of events, I am confident in being a valuable asset to you as an individual who is committed, dependable and efficient under pressure.",
  aboutParaThree:
    "During my free time, I enjoy sports (swimming), baking and socialising! Sometimes, I do take out my musical instruments for old time's sake.",
  aboutParaFour:
    "I am intending to pursue a finance-related masters programme (Quantitative Finance or Financial Engineering) and a MBA in the future.",
  aboutImage:
    bbg,
  //   End About Section ---------------------

  // Skills Section ---------------

  skills: [
    {
      img: htmlIcon,
      title: "HTML5",
    },
    {
      img: cssIcon,
      title: "CSS3",
    },
    {
      img: jsIcon,
      title: "JavaScript",
    },
    {
      img: reactIcon,
      title: "ReactJS",
    },
    {
      img: javaIcon,
      title: "Java",
    },
    {
      img: pythonIcon,
      title: "Python",
    },
    {
      img: rIcon,
      title: "R",
    },
    {
      img: sqlIcon,
      title: "SQL",
    },
    {
      img: mongoIcon,
      title: "MongoDB",
    },
    {
      img: zilIcon,
      title: "Scilla (Zilliqa Blockchain)",
    },
    {
      img: adobeIcon,
      title: "Adobe Creative Suite",
    },
    {
      img: figmaIcon,
      title: "Figma",
    },
    {
      img: awsIcon,
      title: "Amazon Web Services",
    },
    {
      img: githubIcon,
      title: "Git",
    },
    {
      img: linuxIcon,
      title: "Linux",
    },
  ],
  // End Skills Section --------------------------

  //   Promotion Section --------------------------
  promotionHeading: "Education",
  promotionPara1:
    "Singapore University of Technology and Design (SUTD)",
  promotionPara2:
    "Bachelor of Engineering (Honours) - Engineering Systems and Design (Distinction)",
  promotionPara3:
    "Business Analytics & Operations Research Focus Track",
  promotionPara4:
    "Minor in Information Systems",
  promotionPara5:
    "University of California (Berkeley)",
  promotionPara6:
    "Computer Science and Financial Economics",  
  // End Promotion Section -----------------

  //   Contact Section --------------
  contactSubHeading: "Let's create our next experience together.",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/yingxuanyeo/"
    },
    { img: githubIcon,
      url: "https://github.com/yingxuan0806"
    },
    {
      img: mailIcon,
      url: "mailto:hello@yingxuanyeo.com"
    }
  ],
  // End Contact Section ---------------
}
